<script setup>
import { onMounted, ref } from 'vue'
import { ApiService } from '@/js/services/api'

const timetable = ref(null)
const classes = ref(null)
const days = ref(['Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri'])
const model = ref({
  key: '',
  day: 'Luni',
})

onMounted(async () => {
  timetable.value = await ApiService.getTimetable()
  classes.value = Object.keys(timetable.value).sort()
  model.value.key = classes.value[0]
})
</script>

<template>
  <div id="timetable-component" class="box">
    <div class="columns is-variable is-3">
      <div class="column">
        <h3 class="is-size-3">Orarul clasei</h3>
      </div>
      <div class="column is-narrow" v-if="classes">
        <dropdown-select
          v-model="model.key"
          v-bind="{
            customClass: 'is-narrow',
            options: classes,
            placeholder: 'Clasa',
            multiple: false,
          }"
        />
      </div>
    </div>

    <div class="field has-addons">
      <b-radio-button
        v-for="day in days"
        :key="day"
        :native-value="day"
        type="is-primary"
        v-model="model.day"
        expanded
      >
        <span class="is-hidden-tablet">
          {{ day.length > 3 ? day.substring(0, 2) : day }}
        </span>
        <span class="is-hidden-mobile">
          {{ day }}
        </span>
      </b-radio-button>
    </div>

    <table v-if="model.key" class="table is-striped is-fullwidth">
      <tbody>
        <tr
          v-for="(entry, hour, index) in timetable[model.key][model.day]"
          :key="index + '-' + hour"
          class="timetable-entry"
        >
          <td width="130">
            <span>{{ hour }}</span>
            <span v-if="entry.ora_stop">– {{ entry.ora_stop }}</span>
          </td>
          <td>
            <p class="has-text-weight-semibold">{{ entry.materie }}</p>
            <small>{{ entry.sala }}</small>
          </td>
          <td class="has-text-primary has-text-right">
            <small>{{ entry.profesor.nume }}</small>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

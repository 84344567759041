import { createApp } from 'vue'

import TimetableBase from '@/vue/components/TimetableBase.vue'
import Buefy from '@/js/services/buefy'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector('#vue-timetable')

  if (element) {
    const app = createApp(TimetableBase)

    Buefy(app)
    app.mount('#vue-timetable')
  }
})
